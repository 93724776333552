<template>
  <div>
    <div>
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        Kepala Cabang Dinas
      </span>
      <small class="text-subtitle-2">Halaman untuk menambah Kepala Cabang Dinas</small>
    </div>
    <div
      class="mb-8"
    >
      <div
        class="pt-8"
      >
        <v-row class="justify-space-between">
          <v-col
            md="4"
            class=""
          >
            <div>
              <v-text-field
                v-model="search"
                label="Cari"
                outlined
                dense
                class="bg-white"
                @change="searchHandler($event)"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            md="8"
            class="d-flex justify-end pr-0"
          >
            <v-col
              md="5"
              class="py-0"
            >
              <div class="d-xl-flex d-lg-flex d-md-flex">
                <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Kabupaten:</span>
                <v-autocomplete
                  label="Semua"
                  dense
                  :items="regencies"
                  item-text="name"
                  item-value="uuid"
                  outlined
                  @change="filterByRegencies($event)"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              md="4"
              class="py-0"
            >
              <div class="d-xl-flex d-lg-flex d-md-flex">
                <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Urutkan:</span>
                <v-autocomplete
                  label="Semua"
                  dense
                  :items="sorts"
                  outlined
                  @change="filterBySort($event)"
                ></v-autocomplete>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div v-if="!isLoadingCard">
        <v-row class="match-height">
          <v-col
            v-for="hob,index in hobs"
            :key="index"
            md="4"
          >
            <v-card min-height="300">
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="black"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a
                      @click.prevent="showFormEdit(hob.uuid)"
                    >
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="../../assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                    <a @click="confirmDestroy(hob.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="../../assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <div class="mb-4">
                  <div class="d-flex justify-center ">
                    <div class="pt-8 mb-4">
                      <v-img
                        width="80"
                        height="90"
                        :src="hob.photo"
                        class="rounded-lg"
                      ></v-img>
                    </div>
                  </div>
                  <h3 class="font-weight-bold text-center">
                    {{ hob.name }}
                  </h3>
                </div>
                <div class="d-flex">
                  <ul class="d-inline-block">
                    <p>NIP</p>
                    <p>NIK</p>
                    <p>Username</p>
                    <p>Telepon</p>
                    <p>Kabupaten</p>
                  </ul>
                  <ul class="d-inline-block">
                    <p>: {{ hob.nip }}</p>
                    <p>: {{ hob.nik }}</p>
                    <p>: {{ hob.username }}</p>
                    <p>: {{ hob.phone }}</p>

                    <div style="width: 50%">
                      <v-chip
                        v-for="regency,index in hob.regencies"
                        :key="index"
                        class="mb-2 "
                      >
                        <span class="text-truncate">
                          {{ regency.name }}
                        </span>
                      </v-chip>
                    </div>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="mb-5">
          <v-col
            v-for="i, index in 6"
            :key="index"
            md="4"
          >
            <v-skeleton-loader
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="isEmpty"
        class="d-flex justify-center"
      >
        <div
          class="text-center"
        >
          <v-img
            src="../../assets/images/vector/empty.svg"
            class="mx-8 my-7"
          ></v-img>
          <p class="mt-7">
            Belum ada Kacabdis
          </p>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="mt-6">
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
        @change="paginationHandler"
      ></v-pagination>
    </div>
    <div class="d-flex justify-center mt-6">
      <v-btn
        color="primary"
        large
        @click="showFormAdd"
      >
        Tambah Kacabdis
      </v-btn>
    </div>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || hob.photo === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Kacabdis': 'Edit Kacabdis'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="hob.name"
            label="Nama Kacabdis"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="mb-4">
          <FileInput
            v-if="dialog =='add'"
            :value="hob.photo"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          >
          </FileInput>
          <FileInput
            v-if="dialog =='edit'"
            v-model="initialFile"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <div>
          <v-text-field
            v-model="hob.nip"
            label="NIP"
            type="number"
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="hob.nik"
            type="number"
            label="NIK"
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="hob.username"
            label="Username"
            outlined
            dense
          ></v-text-field>
        </div>
        <div v-if="dialog ==='add'">
          <v-text-field
            v-model="hob.password"
            label="Password"
            outlined
            dense
            type="password"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="hob.phone"
            label="Telepon"
            outlined
            dense
            type="number"
          ></v-text-field>
        </div>
        <div>
          <v-combobox
            v-if="dialog =='add'"
            v-model="hob.regencies_uuid"
            :items="regencies"
            :loading="isLoadingData"
            item-text="name"
            item-value="uuid"
            label="Pilih Kabupaten"
            multiple
            outlined
            dense
            clearable
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>
                    </strong>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <v-combobox
            v-if="dialog =='edit'"
            v-model="hob.regencies_uuid"
            :items="regencies"
            :loading="isLoadingData"
            item-text="name"
            item-value="uuid"
            label="Pilih Kabupaten"
            multiple
            outlined
            dense
            clearable
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>
                    </strong>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Soal"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../components/ConfirmDialog.vue'
import FileInput from '../components/FileInput.vue'
import ModalDialog from '../components/ModalDialog.vue'

export default {
  name: 'Hob',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiImageMultipleOutline,
      },
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: false,
      isLoadingCard: true,
      isEmpty: false,
      page: 1,
      totalPages: 0,
      dialog: '',
      modalDialog: false,
      confirmDialog: false,
      search: '',
      hob: {
        nip: '',
        nik: '',
        name: '',
        username: '',
        password: '',
        photo: null,
        phone: '',
        regencies_uuid: [],
      },
      regencies_uuid: [],
      hobs: [],
      service: 'hob',
      regencies: [],
      regencyName: [],
      order: '',
      sorts: ['Terbaru', 'Terlama'],
      province_uuid: '',
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.hob.photo === 'string') {
          return new File([this.hob.photo], this.hob.photo, { type: 'text/plain' })
        }

        return this.hob.photo
      },
      set() {
        return this.hob.photo
      },
    },
  },
  watch: {
    hob: {
      handler() {
        const valid = []
        const requiredField = ['name', 'nip', 'nik', 'username', 'password', 'phone', 'regencies_uuid']
        Object.entries(this.hob).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.isLoadingCard = true
        this.get({ page: this.page })
      },
    },
    order: {
      handler() {
        this.isLoadingCard = true
        this.get({ sort: this.order })
      },
    },
    regencies_uuid: {
      handler() {
        this.isLoadingCard = true
        this.get({ regencies_uuid: this.regencies_uuid })
      },
    },
    search: {
      handler() {
        this.isLoadingCard = true
        this.get({ search: this.search })
      },
    },
  },
  async mounted() {
    this.getProvinceUuid()
    this.listRegency()
    await this.get()
  },
  methods: {
    resetForm() {
      this.hob.photo = null
      this.hob.name = ''
      this.hob.username = ''
      this.hob.password = ''
      this.hob.nik = ''
      this.hob.nip = ''
      this.hob.phone = ''
      this.hob.regencies_uuid = []
    },

    getFile(data) {
      this.hob.photo = data
    },
    getProvinceUuid() {
      this.province_uuid = JSON.parse(localStorage.getItem('user')).user.details[0].province.uuid
    },
    async listRegency() {
      await this.$services.ApiServices.list('regency', { province_uuid: this.province_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },
    async get(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        province_uuid: this.province_uuid,
        per_page: '6',
      }).then(({ data }) => {
        this.hobs = data.data
        this.isLoadingCard = false
        this.isEmpty = false
        if (this.hobs.length < 1) {
          this.isEmpty = true
        }
        this.totalPages = data.meta.last_page
      })
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('nip', this.hob.nip)
      this.formData.append('nik', this.hob.nik)
      this.formData.append('name', this.hob.name)
      this.formData.append('username', this.hob.username)
      this.formData.append('password', this.hob.password)
      this.formData.append('phone', this.hob.phone)
      this.formData.append('photo', this.hob.photo)

      if (this.hob.photo === null || this.hob.photo === '') {
        this.formData.delete('photo')
      }
      this.hob.regencies_uuid.forEach(item => {
        this.formData.append('regencies_uuid[]', item.uuid)
      })
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.get()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      const newData = []
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.hob = { ...data.data, regencies_uuid: data.data.regencies }
        },
        err => {
          console.error(err)
        },
      )
    },
    async update() {
      this.dialog = 'edit'
      this.modalDialog = true
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('nip', this.hob.nip)
      this.formData.append('nik', this.hob.nik)
      this.formData.append('name', this.hob.name)
      this.formData.append('username', this.hob.username)
      this.formData.append('password', this.hob.password)
      this.formData.append('phone', this.hob.phone)
      this.formData.append('photo', this.hob.photo)
      this.hob.regencies_uuid.forEach(item => {
        this.formData.append('regencies_uuid[]', item.uuid)
      })
      if (this.hob.photo === null || typeof this.hob.photo === 'string') {
        this.formData.delete('photo')
      }

      await this.$services.ApiServices.update(this.service, this.formData, this.hob.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.get()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.hob.uuid = uuid
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.hob.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.confirmDialog = false
          this.isLoadingButton = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.get()
    },
    filterBySort(data) {
      if (data === 'Terbaru') {
        this.order = 'asc'
      } else if (data === 'Terlama') {
        this.order = 'desc'
      } else if (data === 'Semua') {
        this.order = ''
      }
    },
    filterByRegencies(data) {
      this.regencies_uuid = data ?? ''
    },
    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.btn-delete {
  color: #e30000 !important;
}
</style>
